.searchbar {
	border:none;
	font-family: 'Roboto', sans-serif;
	font-size:22px;
	border: 1px solid #e5205e;
	font-weight: 500;
	color: black;
	width: 100%;
	padding: 8px 8px 8px 16px;
	height: 56px;
	border-radius: 4px;
}
.searchbar::placeholder {
	font-size: 18px;
	font-weight: 400;
	color: var(--primary-color);
	text-align: left;
	font-style: italic;
}
.searchbar:focus {
	border: 1px solid var(--primary-color);
	outline: none;
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.searchbar:focus::placeholder {
	color: var(--primary-color);
}
.searchbarContainer {
	max-width: 1024px;
	margin: 0 auto;
	position: relative;
	padding: 16px 0px 16px 0px;
	margin-top: 16px;
	border-radius: 8px;
}
.searchbarContainer label {
	font-size: .9rem;
	top:-12px;
	position: absolute;	
	color: #fff;
	background-color: var(--primary-color);
	display: none;
}
.textWrapper{
	display: flex;
	align-items: center;
	margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
    .searchbarContainer {
		max-width: 100%;
		margin: 0 auto;
		padding: 8px;
		background-color: transparent;
		box-shadow: none;
	}
	.searchbar {
		border-radius: 8px;
		font-size: 16px;
		border: 1px solid #fff;
		height: 48px;
		color: black;

	}
	.searchbar:focus {
		color: black;
	}
	.searchbar::placeholder{
		font-size: 16px;
	}
	.textWrapper{
		display: none;
	}
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .searchbarContainer {
		padding-left: 32px;
		padding-right: 32px;
	}
}
