.navbar{
	flex-grow: 2;
	padding-left:64px;
	padding-right: 64px;
}
.navbar ul {
	display: flex;
	list-style: none;
	margin-left: auto;
	align-items: center;
	justify-content: space-around;
	margin:0 auto;
	padding:0;
}
.navbar ul li{
	color: #fff;
	font-weight: normal;
	font-size: 16px;
	border-bottom: 1px solid transparent;
	position: relative;
	cursor: pointer;
	font-weight: 600;
}
.navbar ul li:hover .subMenu {
	display: block;
}

.navbar .subMenu {
	margin: 0;
	padding:0;
	position: absolute;
	z-index: 1001;
	display: block;
	background-color: #e5205e;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
	display: none;
	border:1px solid white;
}
.navbar .subMenu li {
	display: block;
	list-style: circle;
	font-size: 16px;
	line-height: 28px;
	margin: 0;
	padding-left: 8px;
	padding-right: 8px;
	font-weight: 500;
	cursor: pointer;
	color: white !important;
}

.navbar .subMenu li a {
	color: #fff !important;
	text-decoration: none;
}
.navbar .subMenu li:hover{
	background:#fff;
	color: var(--primary-color) !important;
	cursor: pointer;
}
.navbar .subMenu li:hover a {
	background:#fff;
	color: var(--primary-color) !important;
	cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .navbar {
		display: none;
	}
}

@media only screen and (min-width: 600px) and (max-width: 1366px) {
    .navbar {
		display: block;
		flex-grow: none;
		margin: 8px 0 0 0;
		padding: 8px 32px 8px 32px;
		background-color:#e5205e;

	}
	.navbar ul {
		display: block;
	}
	.navbar ul li{
		display: inline-block;
		margin-right: 16px;
	}
}