.mainWrapper {
	margin: 0 auto;
	max-width: 1024px;
}
.header {
	display: flex;
	background: var(--primary-color);
	color: white;
	height: 56px;
	font-size: 18px;
	line-height: 56px;
	padding-left:16px;
	align-items: center;
}
.headerInner {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
@media only screen and (max-width: 600px) {
    .mainWrapper {
		width: 100%;
	}
	.headerWrapper {
		position: sticky;
		top: 0;
		z-index: 10001;	
	}
	.header {
		font-size: 16px;	
	}
	.searchBarWrapper {
		background: transparent;
		padding: 0;
	}
	.headerInner {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
	.header {
		padding-top: 8px;
		display: block;
		height: auto;
		line-height: normal;
		padding-left: 0px;
	}

	.headerInner {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-left: 32px;
	}
}